<template>
  <div class="footer-wrapper">
    <div>
      <div class="footer-nav">
        <div class="first">
          <!-- <img
            :src="require('@/assets/images/index/newLogo3.png')"
            alt
            class="footer-logo"
          /> -->
          <p class="consulting">
            有关公租房、人才公寓、智慧公寓、智慧校园、智慧园区<br />网约房销售解决方案请咨询:
          </p>
          <p class="tel" @click="callPhone('18017053505')">
            销售咨询热线:180-1705-3505
          </p>
          <p class="tel" @click="callPhone('15618298982')">
            市场服务热线:138-1752-8964
            <!-- <span style="margin-left: 10px">(微信同号)</span> -->
          </p>
          <!-- <div class="telTwo" v-if="isShowLink">
            <div class="telTwoName">
              <span>友情链接:</span>
            </div>
            <div class="telTwoContent">
              <a
                style="color: #fff; margin-right: 6px"
                :href="item.url"
                target="_blank"
                v-for="(item, index) in friendsList"
                :key="index"
                >{{ item.name }}</a
              >
            </div>
          </div> -->
        </div>
        <div class="second">
          <img
            src="@/assets/images/index/gongzhonghao.jpg"
            alt=""
            class="footerImg"
          />
          <p class="weixinName">江苏新巢天诚智能技术有限公司</p>
          <p class="weixinText">
            Jiangsu new nest tc smart systems intelligent technology co., LTD
          </p>
          <p class="weixinName">浙江天诚熵智科技有限公司</p>
          <p class="weixinText">
            Zhejiang Tiancheng Entropy Intelligence Technology Co., Ltd
          </p>
        </div>
        <div class="third">
          <div style="text-align: left">
            <p class="title">网站导航</p>
            <!-- <p class="label">首页</p>
            <p class="label">新闻中心</p>
            <p class="label">产品中心</p>
            <p class="label">解决方案</p>
            <p class="label">合作伙伴</p>
            <p class="label">关于我们</p>
            <p class="label">技术支持</p> -->

            <p
              class="label"
              @click="handleClick(item.orderFiled, item.gatewayName)"
              v-for="item in navList"
              :key="item.id"
            >
              {{ item.name }}
            </p>
          </div>
          <div class="rightContent">
            <div>
              <p class="title">公司地址</p>
              <p class="label2">苏州市工业园区金鸡湖大道<br />99号20幢三楼</p>
            </div>

            <div>
              <p class="service">全国服务热线</p>
              <p class="contact">400-8080-557</p>
            </div>

            <div>
              <p class="service">Email:</p>
              <p class="contact">tc@tcsmart.com.cn</p>
            </div>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="record" @click="goBeian">
          © {{ currentYear }} 备案号：苏ICP备20023386号-2
        </div>
      </div>
      <!-- <div class="PeopleLink" v-if="isShowLink">
        <div>
          <span style="color: #666">友情链接:</span>
          <a
            style="color: #666; margin-right: 6px"
            :href="item.url"
            target="_blank"
            v-for="(item, index) in friendsList"
            :key="index"
            >{{ item.name }}</a
          >
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getFriendList } from "@/api/newWebsite";
export default {
  name: "footer-nav",
  data() {
    return {
      env_type: "pro",
      title: "",
      // navList: this.$store.state.navList,
      navList: JSON.parse(localStorage.getItem("navList")),
      currentYear: new Date().getFullYear(),
      friendsList: [],
      isShowLink: true,
      screenWidth: null,
    };
  },

  methods: {
    //拨打电话
    callPhone(phoneNumber) {
      // console.log('拨打电话',phoneNumber)
      window.location.href = "tel://" + phoneNumber;
    },

    handleClick(index, routerName) {
      sessionStorage.setItem("checkedIndex", index);
      switch (index) {
        case 1:
          // this.$router.push({ name: "Home" });
          this.$router.push({ name: routerName });
          break;
        case 2:
          // this.$router.push({ name: "press-center" });
          this.$router.push({ name: routerName });
          break;
        case 3:
          // this.$router.push({ name: "product-center" });
          // this.$router.push({ name: routerName });
          
          this.$router.push({
            name: "product-center",
            query: { id: this.$store.state.productId },
          });
          
          break;
        case 4:
          // this.$router.push({ name: "" });
          // this.$router.push({ name: routerName });

          this.$router.push({
            name: "solution",
            query: { id: this.$store.state.solutionId },
          });
          sessionStorage.setItem("checkedIndex", 4);

          break;
        case 5:
          // this.$router.push({ name: "cooperation" });
          this.$router.push({ name: routerName });
          break;
        case 6:
          // this.$router.push({ name: "about-us" });
          this.$router.push({ name: routerName });
          break;
        case 7:
          // this.$router.push({ name: "technical-support" });
          this.$router.push({ name: routerName });
          break;
        // case 8:
        //   this.$router.push({ name: "technical-support" });
        //   break;
        // case 9:
        //   this.$router.push({ name: "user-center" });
        //   this.$store.commit("changeCurrentNav", 1);
        //   break;
      }
    },
    goBeian() {
      window.open("https://beian.miit.gov.cn/");
    },

    //获取友情链接

    getFriendListData() {
      getFriendList({})
        .then((res) => {
          if (res.code == 0) {
            this.friendsList = res.data;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          // this.$Message.error("");
        });
    },

    goFrined(url) {
      window.open(url);
    },
  },
  created() {
    this.env_type = process.env.VUE_APP_ENV_TYPE;
    this.title = process.env.VUE_APP_PEOJECT_TITLE;
    // this.getFriendListData();  
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      // console.log("宽度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 768) {
        // console.log("屏幕宽度小于768了");
        this.isShowLink = false;
      } else {
        // console.log("屏幕宽度大于768了");
        this.isShowLink = true;
      }
    },
  },
};
</script>

<style scoped>
.footer-wrapper {
  /* margin-top: 40px; */
  width: 100%;
  min-height: 370px;
  background-color: #1d4b73;
  color: white;
  border:1px solid transparent;
  box-sizing: border-box;
}
.footer-nav {
  max-width: 1280px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.first {
  margin-top: 30px;
  text-align: left;
  width: 33%;
}
.consulting {
  color: #c89a69;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 400;
}
.tel {
  font-size: 16px;
  letter-spacing: 0px;
  color: #fff;
  line-height: 20px;
  margin-top: 15px;
}
.telTwo {
  display: flex;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  line-height: 20px;
  margin-top: 20px;
}
.telTwoName {
  width: 60px;
  color: #666;
}
.telTwoContent {
  flex: 1;
}
.telTwoContent span {
  cursor: pointer;
}
.second {
  margin-top: 30px;
  /* margin-left: 50px; */
  width: 38%;
}
.weixinName {
  width: 100%;
  font-size: 21px;
  white-space: nowrap;
  margin-top: 15px;
  color: #c89a69;
}
.weixinText {
  width: 100%;
  font-size: 10px;
  text-align: center;
  color: #c89a69;
}
.third {
  margin-top: 30px;
  /* margin-left: 80px; */
  display: flex;
  flex: 1;
}

.title {
  font-size: 20px;
  color: #c89a69;
  letter-spacing: 0px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
}
.label {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
}
.label2 {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 400;
}
.service {
  color: #c89a69;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  font-weight: 400;
}
.contact {
  font-size: 15px;
  margin-top: 4px;
  font-weight: 400;
}
.line {
  width: 100%;
  /* height: 20px; */
  border-top: 1px solid #4f5b5e;
  margin-top: 20px;
}
.record {
  color: #c89a69;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin-top: 12px;
  padding-bottom: 8px;
  cursor: pointer;
}
.rightContent {
  margin-left: 100px;
  text-align: left;
}
.footerImg {
  width: 144px;
  height: 144px;
}
.footer-logo {
  width: 300px;
  height: 52px;
}

.PeopleLink {
  width: 100%;
  min-height: 20px;
  /* background: #2b2f30; */
  display: flex;
  padding-top: 2px;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .footer-nav {
    display: block;
    padding-left: 10px;
  }
  .first {
    width: 100%;
    text-align: center;
  }
  .third {
    display: none;
  }
  .second {
    text-align: center;
    width: 100%;
  }
  .weixinText {
    text-align: center;
  }
  .rightContent {
    margin-left: 0;
  }
  .line {
    text-align: center;
  }
  .footerImg {
    width: 88px;
    height: 88px;
  }
  .footer-logo {
    width: 230px;
    height: 42px;
  }
}
</style>
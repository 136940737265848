import {
    post,get
} from '../libs/api.request'
//登录
export const login = (params) => post('/api/login/PCLogin', params);

//获取验证码
export const getYanMessage = (params) => post('/api/login/getYanMessage', params);

//登出
export const loginOut = (params) => post('/api/login/customerLoginOut', params);

//修改密码
export const changePassword = (params) => post('/api/utCustomerRenter/resetPassword', params);

//重置密码
export const changeForgetPassword = (params) => post('/api/login/changePwByMessage', params);

//（判断有无注册号码注册了就返回true，否则注册后返回true）优惠券
export const changePwByMessage = (params) => post('/api/login/couponCheck', params);

//领取优惠券(新人券)
export const receivedCoupon = (params) => post('/api/utCpnCoupon/getNewcomerCoupon', params);

//根据ID获取优惠活动(无验证)
export const getActivityByIdForNewcomer = (params) => get('/api/wxPc/getActivityByIdForNewcomer', params);
import axios from 'axios'
import QS from 'qs'
import config from "../config/index"
import {
    handleRequest
} from "./tools"
import router from '../router';
import store from '../store/index'

// 环境的切换
if (process.env.NODE_ENV == 'development') {
    //开发环境
    axios.defaults.baseURL = config.base.baseUrl;
} else if (process.env.NODE_ENV == 'debug') {
    //生产环境
    axios.defaults.baseURL = config.base.baseUrl;
} else if (process.env.NODE_ENV == 'test') {
    //测试环境
    axios.defaults.baseURL = config.base.baseUrl;
} else if (process.env.NODE_ENV == 'production') {
    //生产环境
    axios.defaults.baseURL = config.base.baseUrl;
}

//设置请求超时
axios.defaults.timeout = 1000000;

//post请求头的设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'content-type:application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.withCredentials = true
// if (JSON.parse(localStorage.getItem("userInfo"))) {
//     debugger
//     axios.defaults.headers.customer_token = JSON.parse(localStorage.getItem("userInfo")).customer_token;
//     console.log("111111", JSON.parse(localStorage.getItem("userInfo")).customer_token)
// }

// http request 拦截器
// axios.interceptors.request.use(
//     config => {
//         if (!localStorage.getItem('AuthToken')) {
//             router.replace(
//                 { name: "login" },
//                 // query: {
//                 //     redirect: router.currentRoute.fullPath
//                 // }
//             )
//             // this.$Message.error("请重新登录")
//         }
//         return config;
//     },
//     err => {
//         return Promise.reject(err);
//     });

// http response 拦截器
axios.interceptors.response.use(
    response => {
        // console.log("lanjieqi1:"+JSON.stringify(response))
        // console.log("拦截器" + JSON.stringify(response))
        // console.log(response);
        if (response.data.code == 701) {
            router.replace("login")
            store.commit("changeUserCenter", false);
            store.commit("changeLogin", true);
            sessionStorage.setItem("checkedIndex", 7);
            localStorage.removeItem("userInfo");
        }
        return response;
    },
    err => {
        if (err.data.code == 701) {
            router.replace("login")
            localStorage.removeItem("userInfo");
        }
        return Promise.reject(err.msg)
    });
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
        axios.defaults.headers.customerToken = JSON.parse(localStorage.getItem("userInfo")).customer_token;
        // axios.defaults.headers.setItem("customer-token")
    } else {
        axios.defaults.headers.customerToken = ""
    }
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}


/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
        axios.defaults.headers.customerToken = JSON.parse(localStorage.getItem("userInfo")).customer_token;
    } else {
        axios.defaults.headers.customerToken = ""
    }
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                // console.log(res.data)
                resolve(res.data);
            })
            .catch(err => {
                // console.log(err)
                reject(err.data)
            })
    });

}

/*
 *  附件上传方法， 组装formData，参考可见add-policy.vue
 */
export function postAttachment(url, formData) {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
        axios.defaults.headers.customerToken = JSON.parse(localStorage.getItem("userInfo")).customer_token;
    } else {
        axios.defaults.headers.customerToken = ""
    }
    let headers = {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
        }
    }
    return new Promise((resolve, reject) => {

        axios.post(url, formData, headers).then(res => {
            console.log(res);
            resolve(res.data);
        }, function (err) {
            console.log(err);
            reject(err.data)
        }).catch(err=>{
            console.log(err);
        })
    });
}

export function postJson(url, params) {
    if (JSON.parse(localStorage.getItem("userInfo"))) {
        axios.defaults.headers.customerToken = JSON.parse(localStorage.getItem("userInfo")).customer_token;
    } else {
        axios.defaults.headers.customerToken = ""
    }
    let headers = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    return new Promise((resolve, reject) => {

        axios.post(url, params, headers).then(res => {
            // console.log(res);
            resolve(res.data);
        }, function (err) {
            // console.log(err);
            reject(err.data)
        }).catch(err=>{
            console.log(err);
        })
    });
}
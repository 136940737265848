import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UserHome from "@/views/user-center/user-index.vue";
import UserCenter from "@/views/user-center/userCenter.vue"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "天诚-NB智能门锁-公租房人才公寓管理系统-智慧公寓管理系统-智慧校园管理系统-全场景租住解决方案"
        }
    },
    {
        path: "/press-center",
        name: "press-center",
        component: () => import("@/views/press-center.vue"),
        meta: {
            title: "天诚-新闻中心NB智能门锁，NB门锁，NBIOT智能门锁，NBIOT智能锁，智能门锁，智能电表，智能水表，智能烟感，NBIOT"
        }
    },
    {
        path: "/product-center",
        name: "product-center",
        component: () => import("@/views/product-center.vue"),
        meta: {
            title: "天诚-产品中心，NB智能门锁，NB-IOT智能门锁，NB门锁，NB智能锁，NBIOT智能门锁，NBIOT智能锁，智能电表，智能水表，智能烟感，NB门磁，NBIOT，智能硬件",
            keepAlive: true // 标记此组件需要缓存
        }
    },
    {
        path: "/cooperation",
        name: "cooperation",
        component: () => import("@/views/cooperation.vue"),
        meta: {
            title: "合作伙伴，公租房案例，人才公寓案例，智慧公寓案例，NB智能门锁案例"
        }
    },
    {
        path: "/public-cooperation",
        name: "public-cooperation",
        component: () => import("@/views/public-cooperation.vue"),
        meta: {
            title: "合作伙伴，公租房案例，人才公寓案例，智慧公寓案例，NB智能门锁案例"
        }
    },
    {
        path: "/school-cooperation",
        name: "school-cooperation",
        component: () => import("@/views/school-cooperation.vue"),
        meta: {
            title: "合作伙伴，公租房案例，人才公寓案例，智慧公寓案例，NB智能门锁案例"
        }
    },
    {
        path: "/technical-support",
        name: "technical-support",
        component: () => import("@/views/technical-support.vue"),
        meta: {
            title: "技术支持，智能硬件技术支持，公租房管理系统，保障房管理系统，人才公寓管理系统，租赁服务平台，公租房服务平台，人才公寓服务平台"
        }
    },
    {
        path: "/device-detail",
        name: "device-detail",
        component: () => import("@/views/device-detail.vue"),
        meta: {
            title: "天诚-产品中心，NB智能门锁，NB-IOT智能门锁，NB门锁，NB智能锁，NBIOT智能门锁，NBIOT智能锁，智能电表，智能水表，智能烟感，NB门磁，NBIOT，智能硬件"
        }
    },
    {
        path: "/news-detail",
        name: "news-detail",
        component: () => import("@/views/news-detail.vue"),
        meta: {
            title: "天诚-新闻中心NB智能门锁，NB门锁，NBIOT智能门锁，NBIOT智能锁，智能门锁，智能电表，智能水表，智能烟感，NBIOT"
        }
    },
    {
        path: "/solution",
        name: "solution",
        component: () => import("@/views/solution.vue"),
        meta: {
            title: "解决方案",
        },
        // beforeRouteEnter: (to, from, next) => {
        //     if(to.path == '/solution' && to.query.id == '8819005328326656'){    //如果进入解决方案-校园，重新定位新版页面
        //         next({ path: '/school' });
        //     }else if(to.path == '/solution' && to.query.id == '8818659390521344'){  //如果进入解决方案-园区，重新定位新版页面
        //         next({ path: '/park' })
        //     }else if(to.path == '/solution' && to.query.id == '8817824614973440'){  //如果进入解决方案-公租房，重新定位新版页面
        //         next()
        //     }else if(to.path == '/solution' && to.query.id == '4124609738611097'){  //如果进入解决方案-人才公寓，重新定位新版页面
        //         next()
        //     }else{
        //         next()
        //     }
        // }
    },
    {
        path: "/school",
        name: "school",
        component: () => import("@/views/school.vue"),
        meta: {
            title: "解决方案",
        },
    },
    {
        path: "/park",
        name: "park",
        component: () => import("@/views/park.vue"),
        meta: {
            title: "解决方案",
        },
    },
    {
        path: "/gzf",
        name: "gzf",
        component: () => import("@/views/gzf.vue"),
        meta: {
            title: "解决方案",
        },
    },
    {
        path: "/hotel",
        name: "hotel",
        component: () => import("@/views/hotel.vue"),
        meta: {
            title: "解决方案",
        },
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import("@/views/about-us.vue"),
        meta: {
            title: "关于我们，专注于公租房人才公寓智慧校园解决方案领导品牌，行业领导品牌-江苏新巢天诚智能技术有限公司"
        }
    },
    {
        path: "/contact-us",
        name: "contact-us",
        component: () => import("@/views/contact-us.vue"),
        meta: {
            title: "联系我们-江苏新巢天诚智能技术有限公司"
        }
    }
];

const router = new VueRouter({
    mode:"history",
    base: process.env.BASE_URL,
    routes,
    //禁止浏览器后退
    scrollBehavior: () => {
        history.pushState(null, null, document.URL)
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) { // 未匹配到路由
        next({ path: '/', replace: true });
        sessionStorage.setItem("checkedIndex", 1);
    } else {
        next();
    }
});


//解决重复点击路由，控制台报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

router.afterEach((to, from) => {
    document.title = to.meta.title //在全局后置守卫中获取路由元信息设置title

})


export default router;
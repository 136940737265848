import {get, post ,postJson} from '../libs/api.request'

//获取合作伙伴
export const getCooperationList = (params) => get('partner', params);

//获取关于我们
export const getAboutList = (params) => get('about', params);

//获取技术支持
export const getTechnicalList = (params) => get('technical', params);
//获取产品中心
export const getProductList = (params) => get('product', params);

//新闻中心
export const getnewsList = (params) => get('news', params);

//获取banner图
export const getBannerList = (params) => get('slideshow', params);

//获取产品类别
export const getProductIdList = (params) => get('category/list', params);

//获取产品详情
export const getProductDetail = (params) => get('product/info', params);

//获取解决方案列表
export const getSolutionList = (params) => get('solution', params);

//获取解决方案详情
export const getSolutionDetail = (params) => get('solution/info', params);

//获取导航栏数据
export const getNavigationList = (params) => get('navigation', params);

//获取新闻详情
export const getNewsDetail = (params) => get('news/info', params);

//获取友情链接
export const getFriendList = (params) => get('friend', params);
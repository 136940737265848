<template>
  <div class="outer">
    <div class="user-head">
      <img
        src="@/assets/images/userImage/user_head.png"
        alt
        width="100px"
        height="100px"
        style="margin-left:30px"
      />
      <dl class="user-info">
        <dd>
          你好，{{userName}}
          <span
            style="border:1px solid #DCDCDC;width:78px;height:28px;display:inline-block;line-height:24px;text-align:center"
          >
            <img
              src="@/assets/images/userImage/user_icon01.png"
              alt
              style="position: relative;top:2px"
            />
            <span style="color:#EA5414; font-size:14px;padding-left:5px;">已验证</span>
          </span>
        </dd>
        <dd>
          欢迎来到个人中心
          <img
            src="@/assets/images/userImage/user_icon02.png"
            alt
            style="margin-left:10px;position: relative;top:2px"
          />
          <span
            style="color:#EA5414; font-size:14px;cursor: pointer;padding-left:5px;margin-top:-5px"
            @click="changePassword"
          >修改密码</span>
          <img
            src="@/assets/images/userImage/loginout.png"
            alt
            style="margin-left:10px;position: relative;top:2px"
          />
          <span
            style="color:#EA5414; font-size:14px;cursor: pointer;padding-left:5px;margin-top:-5px"
            @click="loginOut"
          >退出登录</span>
        </dd>
        <dd>
          pc端官网只提供预约看房的功能，签约或支付请
          <span style="color:#EA5414;  cursor: pointer;">关注“恒泰星寓"微信公众号</span>
        </dd>
      </dl>
    </div>

    <!-- 我的收藏 -->
    <div>
      <div class="name_container">
        <Row type="flex" justify="space-around" class="code-row-bg">
          <i-col span="12" class="name">我的收藏</i-col>
          <i-col span="12" class="more" @click.native="moreCollection">更多>></i-col>
        </Row>

        <div class="collection">
          <Row :gutter="32">
            <i-col span="8" class="bg_shadow" v-for="(item,index) in myCollectionList" :key="index">
              <img :src="item.innerImg" alt height="200px" width="100%" />
              <div class="live_introduce">
                <p style="font-size:16px">{{item.productName}}</p>
                <p style="padding-top:5px;color:#EA5414;font-size:16px">{{item.price}}/月起</p>
              </div>
            </i-col>
            <!-- <i-col span="7" class="bg_shadow">
              <img
                src="@/assets/images/userImage/housetype_pic02.png"
                alt
                height="200px"
                width="100%"
              />
              <div class="live_introduce">
                <p style="font-size:16px">简约风一室一厅</p>
                <p style="padding-top:5px;color:#EA5414">2000/月起</p>
              </div>
            </i-col>
            <i-col span="7" class="bg_shadow">
              <img
                src="@/assets/images/userImage/housetype_pic03.png"
                alt
                height="200px"
                width="100%"
              />
              <div class="live_introduce">
                <p style="font-size:16px">简约风一室一厅</p>
                <p style="padding-top:5px;color:#EA5414">2000/月起</p>
              </div>
            </i-col>-->
          </Row>
        </div>
      </div>
      <div class="name_container">
        <Row type="flex" justify="space-around" class="code-row-bg">
          <i-col span="12" class="name">我的预约</i-col>
          <i-col span="12" class="more" @click.native="moreAppointment">更多>></i-col>
        </Row>

        <div class="collection">
          <Row :gutter="32">
            <i-col span="8" class="bg_shadow" v-for="(item,index) in appointmentList" :key="index">
              <img
                :src="item.productId?item.innerImg:item.publicityImag.split(',')[0]"
                alt
                height="200px"
                width="100%"
              />
              <div class="live_introduce">
                <p
                  style="font-size:16px;padding-bottom:6px"
                  v-text="item.productId?item.productName:item.name"
                ></p>
                <p style="font-size:14px;color:#666666;padding-bottom:6px">
                  看房日期:
                  <span style="padding-left:10px">{{item.seeDate}}</span>
                </p>
                <p style="font-size:14px;color:#666666;padding-bottom:6px">
                  联系人:
                  <span style="padding-left:23px">{{item.username}}</span>
                </p>
                <p style="font-size:14px;color:#666666;padding-bottom:6px">
                  联系电话:
                  <span style="padding-left:10px">{{item.mobile}}</span>
                </p>
              </div>
            </i-col>
            <!-- <i-col span="7" class="bg_shadow">
              <img
                src="@/assets/images/userImage/housetype_pic02.png"
                alt
                height="200px"
                width="100%"
              />
              <div class="live_introduce">
                <p style="font-size:16px;padding-bottom:6px">温馨型 一居室 大床房</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">看房日期:2020-11-04</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">联系人:张鑫磊</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">联系电话:18994888737</p>
              </div>
            </i-col>
            <i-col span="7" class="bg_shadow">
              <img
                src="@/assets/images/userImage/housetype_pic03.png"
                alt
                height="200px"
                width="100%"
              />
              <div class="live_introduce">
                <p style="font-size:16px;padding-bottom:6px">温馨型 一居室 大床房</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">看房日期:2020-11-04</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">联系人:张鑫磊</p>
                <p style="font-size:13px;color:#666666;padding-bottom:6px">联系电话:18994888737</p>
              </div>
            </i-col>-->
          </Row>
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <Modal
      v-model="isShowPassword"
      title="修改密码"
      @on-ok="comfirmOk"
      @on-cancel="comfirmCancel"
      ref="modal"
      class="passwordModal"
      :mask-closable="false"
    >
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="90">
        <FormItem label="用户名" prop="phone">
          <i-input v-model="formValidate.phone" readonly></i-input>
        </FormItem>
        <FormItem label="原密码" prop="password">
          <i-input v-model="formValidate.password" type="password"></i-input>
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <i-input v-model="formValidate.newPassword" type="password"></i-input>
        </FormItem>
        <FormItem label="确认密码" prop="comfirmPassword">
          <i-input v-model="formValidate.comfirmPassword" type="password"></i-input>
        </FormItem>
      </Form>
    </Modal>

    <!-- 退出登录 -->
    <Modal
      v-model="isShowLoginOUt"
      title="退出登录"
      ref="modalLoginOut"
      class="loginOUtModal"
      width="420"
    >
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-information-circle"></Icon>
        <span>退出登录</span>
      </p>
      <div style="text-align:center">
        <p style="color:#333;font-size:14px">您确定退出恒泰星寓吗？</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="SureloginOut">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { loginOut, changePassword } from "@/api/login.api";
import { getMyCollection, getMyAppointment } from "@/api/userCenter.api";
export default {
  name: "userCenter",
  data() {
    const pwdCheckValidate = (rule, value, callback) => {
      if (
        this.formValidate.newPassword != "" &&
        this.formValidate.comfirmPassword == ""
      ) {
        callback(new Error("密码不能为空"));
      } else if (
        this.formValidate.newPassword != this.formValidate.comfirmPassword
      ) {
        callback(new Error("确认密码与新密码不相同"));
      } else {
        callback();
      }
    };
    const validateareaSpace = (rule, value, callback) => {
      var regu = new RegExp(/\s/); // 匹配空格
      if (value && regu.test(value)) {
        return callback(new Error("禁止输入空格"));
      } else {
        return callback();
      }
    };
    return {
      myCollectionList: [],
      appointmentList: [],
      userName: JSON.parse(localStorage.getItem("userInfo")).account,
      isShowPassword: false,
      isShowLoginOUt: false,
      formValidate: {
        phone: JSON.parse(localStorage.getItem("userInfo")).account,
        password: "",
        newPassword: "",
        comfirmPassword: ""
      },
      ruleValidate: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change"
          }
        ],
        password: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "change"
          }
        ],
        newPassword: [
          {
            required: true,
            validator: validateareaSpace,
            trigger: "change"
          },
          {
            required: true,
            message: "请填写6-16位数字密码",
            trigger: "change"
          },
          {
            type: "string",
            min: 6,
            max: 16,
            message: "请填写6-16位数字密码",
            trigger: "change"
          },
          {
            type: "string",
            pattern: /^\d+$/,
            message: "请填写6-16位数字密码",
            trigger: "change"
          }
        ],
        comfirmPassword: [
          {
            required: true,
            message: "请填写确认密码",
            trigger: "change"
          },
          { required: true, validator: pwdCheckValidate, trigger: "change" },
          {
            required: true,
            validator: validateareaSpace,
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    //修改密码
    changePassword() {
      this.isShowPassword = true;
    },
    //确认修改密码
    comfirmOk() {
      this.$refs.formValidate.validate(valid => {
        if (valid) {
          changePassword({
            newPassword: this.formValidate.newPassword,
            oldPassword: this.formValidate.password
          }).then(res => {
            if (res.success) {
              this.$Message.success("修改密码成功!");
              this.isShowPassword = false;
              this.formValidate.password = "";
              this.formValidate.newPassword = "";
              this.formValidate.comfirmPassword = "";
              this.$router.push({ name: "login" });
              localStorage.removeItem("userInfo");
              this.$store.commit("changeUserCenter", false);
              sessionStorage.setItem("checkedIndex", 7);
            } else {
              this.$Message.error(res.msg);
              this.isShowPassword = true;
              //   this.$refs.modal.visible = true
            }
          }).catch(err=>{
            console.log(err)
          });
        } else {
          this.isShowPassword = true;
          // this.$refs.modal.visible = true
        }
      });
    },
    //取消修改密码
    comfirmCancel() {
      this.isShowPassword = false;
      this.formValidate.password = "";
      this.formValidate.newPassword = "";
      this.formValidate.comfirmPassword = "";
    },
    // 我的收藏更多
    moreCollection() {
      this.$router.push({ name: "Collection" });
      this.$store.commit("changeCurrentNav", 2);
      //   console.log(this.$store.state.currentNav);
    },
    //我的预约更多
    moreAppointment() {
      this.$router.push({ name: "appointment" });
      this.$store.commit("changeCurrentNav", 3);
      //   console.log(this.$store.state.currentNav);
    },

    //获取我的收藏
    getMyCollection() {
      getMyCollection()
        .then(res => {
          if (res.success) {
            this.myCollectionList = res.data.splice(0, 3);
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch(err => {
          this.$Message.error("获取我的收藏失败,请稍后在试");
        });
    },
    //获取我的预约
    getMyAppointmentList() {
      getMyAppointment()
        .then(res => {
          if (res.success) {
            this.appointmentList = res.data.splice(0, 3);
            // console.log("我的预约",this.appointmentList)
          } else {
            this.$Message.error(res.msg);
          }
        })
        .catch(err => {
          this.$Message.error("获取我的预约失败,请稍后在试");
        });
    },

    //退出登录
    loginOut() {
      this.isShowLoginOUt = true;
    },
    //确认退出登录
    SureloginOut() {
      loginOut({
        customer_token: JSON.parse(localStorage.getItem("userInfo"))
          .customer_token
      })
        .then(res => {
          if (res.success) {
            this.$router.push({ name: "login" });
            localStorage.removeItem("userInfo");
            this.$store.commit("changeUserCenter", false);
            sessionStorage.setItem("checkedIndex", 7);
            this.$store.commit("changeLogin", true);
            this.$store.commit("changeRegister", true);
            //   localStorage.setItem("token", "");
          }
        })
        .catch(err => {
          this.$Message.error("退出失败", JSON.stringify(err));
        });
    }
  },
  created() {
    this.getMyCollection();
    this.getMyAppointmentList();
  }
};
</script>

<style scoped>
.user-head {
  text-align: left;
  display: flex;
  height: 180px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
}
.user-info {
  margin-left: 20px;
}

.user-info dd:nth-child(1) {
  font-size: 24px;
  color: #343434;
  padding-bottom: 6px;
}
.user-info dd:nth-child(2) {
  font-size: 18px;
  color: #343434;
  padding-bottom: 6px;
}
.user-info dd:nth-child(3) {
  font-size: 14px;
  color: #343434;
  padding-bottom: 6px;
}
.name_container {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 32px;
}
.name {
  text-align: left;
  color: #343434;
  font-size: 20px;
}
.more {
  text-align: right;
  color: #ea5414;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
}
.bg_shadow {
  box-shadow: 0 0 0 0;
}
.collection {
  margin-top: 20px;
  padding-bottom: 20px;
}
.live_introduce {
  min-height: 70px;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  border: 1px solid #e5e5e5;
  border-top: none;
  margin-top: -5px;
}
.passwordModal >>> .ivu-btn-primary {
  background-color: #ea5414;
  border-color: #ea5414;
}
.passwordModal >>> .ivu-input {
  font-size: 14px;
}
.passwordModal >>> .ivu-form .ivu-form-item-label {
  font-size: 14px;
}
.loginOUtModal >>> .ivu-btn-error {
  background-color: #ea5414;
  border-color: #ea5414;
}
</style>
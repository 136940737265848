<template>
  <div style="background-color: #f5f5f5;min-width:1366px">
    <header-nav></header-nav>
    <div class="user-index">
      <div class="left">
        <ul class="user-menu">
          <li
            v-for="(item, index) in navList"
            :key="index"
            @click="goPath(item.path, item.id)"
            :class="$store.state.currentNav===item.id?'active':''"
          >
            <img :src="$store.state.currentNav===item.id?item.iconSelected:item.icon" alt />
            {{item.name}}
          </li>
        </ul>
      </div>
      <div class="right">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </div>
    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
export default {
  name: "user-index",
  components: { headerNav, footerNav },
  data() {
    return {
      //   currentNav: this.$store.state.currentNav,
      navList: [
        {
          id: 1,
          name: "个人中心",
          path: "/UserCenter",
          icon: require("../../assets/images/userImage/nav_icon01_default.png"),
          iconSelected: require("../../assets/images/userImage/nav_icon01_current.png")
        },
        {
          id: 2,
          name: "我的收藏",
          path: "/Collection",
          icon: require("../../assets/images/userImage/nav_icon02_default.png"),
          iconSelected: require("../../assets/images/userImage/nav_icon02_current.png")
        },
        {
          id: 3,
          name: "我的预约",
          path: "/appointment",
          icon: require("../../assets/images/userImage/nav_icon03_default.png"),
          iconSelected: require("../../assets/images/userImage/nav_icon03_current.png")
        },
        {
          id: 4,
          name: "投诉建议",
          path: "/complaint",
          icon: require("../../assets/images/userImage/nav_icon04_default.png"),
          iconSelected: require("../../assets/images/userImage/nav_icon04_current.png")
        }
      ]
    };
  },
  methods: {
    goPath(path, id) {
      this.$store.commit("changeCurrentNav", id);
      //   this.currentNav = id;
      this.$router.push({ path: path });
    }
  },
  created() {
    this.$store.commit("changeAppointment", true);
    this.$store.commit("changeLocation", 230);
  }
};
</script>

<style scoped>
.user-index {
  width: 1200px;
  margin: auto;
  display: flex;
  /* margin-top: 20px; */
  padding-top: 100px;
  padding-bottom: 40px;
}
.left {
  width: 190px;
  height: 180px;
  background-color: #ffffff;
}
.right {
  flex: 1;
  margin-left: 2%;
  min-height: 800px;
  background-color: #ffffff;
}
.user-menu {
  list-style: none;
  padding: 10px;
  margin: 0px 4px;
  font-size: 14px;
}
.user-menu li {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.user-menu li.active {
  color: #ea5414;
}
.user-menu li img {
  vertical-align: sub;
  margin-right: 10px;
}
</style>
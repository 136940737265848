<template>
  <div>
    <!-- 预约微信 -->
    <div
      class="float_bgc"
      :style="{ height: $store.state.locationColumn + 'px' }"
    >
      <div
        style="cursor: pointer"
        @mouseenter="mousePhone"
        @mouseleave="mousePhoneLeave"
        @click="callPhone('18017053505')"
      >
        <img src="@/assets/images/index/iphone.png" alt  width="30px" height="25px"/>
        <p class="order">电话</p>
      </div>
      <div class="phoneCode" v-if="isPhoneShow" style="padding-top: 25px">
        <!-- <img :src="require('@/assets/images/index/gongzhonghao.jpg')" alt /> -->
        <p>咨询客服</p>
        <p style="margin-bottom: 4px;padding-top: 10px;">180-1705-3505</p>
      </div>

      <div
        style="cursor: pointer;margin-top:10px"
        @mouseenter="mouseCode"
        @mouseleave="mouseCodeLeave"
      >
        <img src="@/assets/images/index/float_icon02.png" alt />
        <p class="order">微信</p>
      </div>
      <div class="AppCode" v-if="isCodeShow" style="padding-top: 4px">
        <img :src="require('@/assets/images/index/jincheng.jpg')" alt />
        <p>微&nbsp;信&nbsp;扫&nbsp;码</p>
        <p style="margin-bottom: 4px">销&nbsp;售&nbsp;咨&nbsp;询</p>
      </div>
      <div class="arrow">
        <img
          src="@/assets/images/index/float_icon03.png"
          alt
          @click="backTop"
        />
      </div>
    </div>
    <!-- 
    <Modal
      v-model="isShowOrder"
      title="预约"
      @on-ok="comfirmOk"
      @on-cancel="comfirmCancel"
      ref="modal"
      class="orderModal"
    >
      <Form :label-width="20" ref="formValidate" :model="formValidate" :rules="ruleValidate">
        <Form-Item class="chooseDate" prop="date">
          <DatePicker
            type="date"
            placeholder="请选择预约时间"
            style="width:90%;"
            v-model="formValidate.date"
            :options="dateOptions"
            :editable="false"  @on-change="chooseDate"
          ></DatePicker>
        </Form-Item>
        <FormItem prop="seeTime" class="seeTimeContainer">
          <Select v-model="formValidate.seeTime" style="width:90%" placeholder="请选择预约看房时间段">
            <Option :disabled="item.isReadOnly"
              v-for="item in seeTime"
              :value="item.codeName"
              :key="item.codeKey"
            >{{ item.codeName }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="seeProject" class="seeTimeContainer">
          <Select v-model="formValidate.seeProject" style="width:90%" placeholder="请选择预约项目">
            <Option v-for="item in allProjectList" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="customerSource" class="seeTimeContainer">
          <Select v-model="formValidate.customerSource" style="width:90%" placeholder="请选择获悉渠道">
            <Option v-for="item in customerSourceList" :value="item.codeKey" :key="item.codeKey">{{ item.codeName }}</Option>
          </Select>
        </FormItem>
        <Form-Item class="chooseDate" prop="rentIntention">
          <Input placeholder="租房意向" style="width:90%" v-model="formValidate.rentIntention" />
        </Form-Item>
        <FormItem prop="appointmentName" class="chooseDate">
          <Input placeholder="请输入预约人姓名" style="width: 90%;" v-model="formValidate.appointmentName" />
        </FormItem>
        <Form-Item class="chooseDate" prop="phone">
          <Input
            placeholder="请输入手机号码"
            style="width:90%"
            v-model="formValidate.phone"
            :maxlength="11"
            @on-change="changePhone"
          />
        </Form-Item>
        <Form-Item class="chooseDate" prop="messageCode">
          <Row>
            <i-col span="13">
              <Input placeholder="请输入验证码" v-model="formValidate.messageCode" class="codeInput" />
            </i-col>
            <i-col span="6">
              <div
                class="getCode"
                :class="disabled?'click-disable':''"
                @click="btnMessageClick"
              >{{btnTitle}}</div>
            </i-col>
          </Row>
        </Form-Item>
      </Form>
    </Modal> -->
  </div>
</template>

<script>
import { getYanMessage } from "@/api/login.api";
import { appointmentLookHouse } from "@/api/houseType.api";
import { getCodeInfoByGroupKey } from "@/api/index.api";
import { getAllProjectList } from "@/api/index.api";
import codeKey from "@/api/enum";
import moment from "moment";
export default {
  name: "loaction-column",
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback("手机号格式不正确");
      } else {
        callback();
      }
    };
    return {
      isShowOrder: false,
      isCodeShow: false,
      isPhoneShow: false,
      disabled: false,
      btnTitle: "获取验证码",
      seeTime: [],
      allProjectList: [],
      yzmCookie: "",
      customerSourceList: [], // 获悉渠道
      formValidate: {
        date: "",
        phone: "",
        rentIntention: "", //租房意向
        messageCode: "",
        seeTime: "", //预约时间段
        appointmentName: "", //预约人姓名
        seeProject: "", //项目
        customerSource: "",
      },
      //验证规则
      ruleValidate: {
        date: [
          {
            required: true,
            type: "date",
            message: "请选择看房时间",
            trigger: "change",
          },
        ],
        seeProject: [
          {
            required: true,
            message: "请输入预约项目",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "change",
          },
          {
            required: true,
            validator: validatePhone,
            trigger: "change",
          },
        ],
        rentIntention: [
          {
            required: true,
            message: "请输租房意向",
            trigger: "change",
          },
        ],
        messageCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "change",
          },
        ],
        seeTime: [
          {
            required: true,
            message: "请输入预约看房时间段",
            trigger: "change",
          },
        ],
        appointmentName: [
          {
            required: true,
            message: "请输入预约人姓名",
            trigger: "change",
          },
        ],
        customerSource: [
          {
            required: true,
            message: "请输入预约获悉渠道",
            trigger: "change",
          },
        ],
      },
      dateOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },
      env_type: "pro",
    };
  },
  methods: {
    backTop() {
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50; // ------
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 20);
    },
    mouseCode() {
      this.isCodeShow = true;
    },
    mouseCodeLeave() {
      this.isCodeShow = false;
    },

    mousePhone() {
      this.isPhoneShow = true;
    },
    mousePhoneLeave() {
      this.isPhoneShow = false;
    },

    //拨打电话
    callPhone(phoneNumber){
      // console.log('拨打电话',phoneNumber)
         window.location.href = "tel://" + phoneNumber;
    },


    clickAppointemnt() {
      //   if (localStorage.getItem("userInfo")) {
      //     this.isShowOrder = true;
      //   } else {
      //     this.$router.push({ name: "login" });
      //     sessionStorage.setItem("checkedIndex", 7);
      //   }
      this.isShowOrder = true;
    },
  },
  created() {},
};
</script>

<style scoped>
.float_bgc {
  width: 52px;
  background-color: #ea5413;
  position: fixed;
  /* left: 95%; */
  right:0.2%;
  bottom: 150px;
  padding-top: 20px;
}
.order {
  color: #ffffff;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.arrow {
  margin-top: 20px;
  cursor: pointer;
}
.orderModal >>> .ivu-btn-primary {
  background-color: #ea5414;
  border-color: #ea5414;
}
.chooseDate >>> .ivu-input {
  height: 42px;
}
.chooseDate >>> i {
  margin-top: 5px;
}
.seeTimeContainer >>> .ivu-select-selection {
  height: 40px;
}
.seeTimeContainer >>> .ivu-select-placeholder {
  line-height: 40px;
  text-align: left;
}
.seeTimeContainer >>> .ivu-select-arrow {
  top: 55%;
}
.seeTimeContainer >>> .ivu-select-selected-value {
  line-height: 40px;
  text-align: left;
  font-size: 14px;
}
.content >>> .ivu-form-item-error-tip {
  left: 34px;
}
.content >>> .ivu-select-item {
  text-align: left;
  font-size: 14px !important;
}
.orderModal >>> .ivu-select-item {
  font-size: 14px !important;
}
.getCode {
  background-color: #faefe8;
  color: #ea5413;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  text-align: center;
  position: relative;
  left: 50px;
  border-radius: 4px;
}
.click-disable {
  pointer-events: none;
}
.codeInput >>> .ivu-input {
  width: 280px;
}
.AppCode {
  width: 220px;
  height: 220px;
  background-color: white;
  font-weight: 500;
  position: absolute;
  right: 55px;
  top: 60px;
  box-shadow: 0 0 5px 0;
  font-size: 15px;
}
.AppCode2 {
  width: 130px;
  height: 130px;
  background-color: white;
  font-weight: 500;
  position: absolute;
  right: 55px;
  top: 60px;
  right: 55px;
  top: 1px;
  box-shadow: 0 0 5px 0;
}
.phoneCode {
  width: 230px;
  height: 100px;
  background-color: white;
  font-weight: 500;
  position: absolute;
  right: 55px;
  top: 2px;
  box-shadow: 0 0 5px 0;
  font-size: 15px;
}
.phoneCode img {
  width: 78px;
  height: 78px;
}
.AppCode img {
  width: 160px;
  height: 160px;
}
.orderModal >>> .ivu-input {
  font-size: 14px;
}
</style>
import { post } from '../libs/api.request'

//首页banner图
export const getBannnerList = (params) => post('/api/utWebBanner/getAllDataList', params);

//获取所有项目
export const getAllProjectList = (params) => post('/api/wxPcUtAstEstate/findAllEstate', params);

//获取项目接口
export const getProjectList = (params) => post('/api/wxPcUtAstEstate/getList', params);

//项目详情
export const getProjectDetail = (params) => post('/api/wxPcUtAstEstate/findById', params);

//根据项目id获取户型模糊查询（layoutId不为空则排除layoutId的对象）
export const getHouseType = (params) => post('/api/lease/UtAstLayout/getAllLayoutByEstateIdAndLayoutName', params);

//获取单个房源详情，根据主键id【√】
export const getHouseDetail = (params) => post('/api/lease/UtAstLayout/getDataById', params);

  //代码组获取区域
export const getCodeInfoByGroupKey = (params) => post('/api/wxPcUtAstEstate/getCodeInfoByGroupKey', params);

//获取热门产房型
export const getHotHouseTypeList = (params) => post('/api/wxPcUtAstProductPublish/getHotProduct', params);


import { render, staticRenderFns } from "./location-column.vue?vue&type=template&id=6e9b3d58&scoped=true"
import script from "./location-column.vue?vue&type=script&lang=js"
export * from "./location-column.vue?vue&type=script&lang=js"
import style0 from "./location-column.vue?vue&type=style&index=0&id=6e9b3d58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9b3d58",
  null
  
)

export default component.exports
<template>
  <div class="home">
    <header-nav></header-nav>
    <div class="main">
      <div class="main-header">
        <Carousel
          autoplay
          v-model="value2"
          loop
          class="banner"
          :autoplay-speed="6000"
        >
          <CarouselItem v-for="(item, index) in bannerList" :key="index">
            <img :src="item.slideshowUrl" alt />
          </CarouselItem>
        </Carousel>
      </div>
      <!-- 应用领域 -->
      <div class="project_container">
        <div class="project">
          <div>
            <div class="project_content">
              <p class="fieldName1">应用领域</p>
              <p class="fieldName2">
                覆盖行业内99%业务，为客户提供全套解决方案
              </p>
            </div>
          </div>
          <!-- <Row :gutter="32">
            <i-col span="6" class="bg_project2 boderStyle">
              <div
                class="bg_project2Children"
                @click="goSolution('8817824614973440')"
              >
                <div>
                  <img
                    src="@/assets/images/index/yingyong2.png"
                    alt=""
                    width="56px"
                    height="56px"
                    style="cursor: pointer"
                  />
                </div>
                <div class="applicationName">公租房.人才公寓</div>
                <div class="applicationContent">
                  通过租住服务平台与智能硬件的相结合，提供完整的租住生命周期方案，帮助运营管理部门对公租房.人才公寓得到有效的监督与管理。
                </div>
                <div class="applicationDeatil">公租房.人才公寓</div>
              </div>
            </i-col>
            <i-col span="6" class="bg_project2 boderStyle">
              <div
                class="bg_project2Children"
                @click="goSolution('8818247132381184')"
              >
                <div>
                  <img
                    src="@/assets/images/index/yingyong.png"
                    alt=""
                    width="56px"
                    height="56px"
                    style="cursor: pointer"
                  />
                </div>
                <div class="applicationName">智慧公寓</div>
                <div class="applicationContent">
                  为公寓运营提供全场景租住解决方案，节省人力物力，全面开放赋能智慧公寓管理。
                </div>
                <div class="applicationDeatil">智慧公寓</div>
              </div>
            </i-col>
            <i-col span="6" class="bg_project2 boderStyle">
              <div
                class="bg_project2Children"
                @click="goSolution('8819005328326656')"
              >
                <div>
                  <img
                    src="@/assets/images/index/yingyong4.png"
                    alt=""
                    width="56px"
                    height="56px"
                    style="cursor: pointer"
                  />
                </div>
                <div class="applicationName">智慧校园</div>
                <div class="applicationContent">
                  通过校园管理平台与NB物联网锁相结合，有效的对实验室、教室、宿舍公寓行为日志等进行管理。
                </div>
                <div class="applicationDeatil">智慧校园</div>
              </div>
            </i-col>
            <i-col span="6" class="bg_project2">
              <div
                class="bg_project2Children"
                @click="goSolution('8818659390521344')"
              >
                <div>
                  <img
                    src="@/assets/images/index/yingyong3.png"
                    alt=""
                    width="56px"
                    height="56px"
                    style="cursor: pointer"
                  />
                </div>
                <div class="applicationName">智慧园区</div>
                <div class="applicationContent">
                  为园区、办公楼提供管理方案，通过人员的集中管理，设备集中管理，提高管理效率。
                </div>
                <div class="applicationDeatil">智慧园区</div>
              </div>
            </i-col>
          </Row> -->

          <div class="bg_cantainer">
            <div class="field" @click="goSolution('8817824614973440')">
              <div class="field_bgc">
                <h3 class="bg_fieldName">公租房</h3>
                <p class="bg_fieldNameEnglish">
                  Public rental 
                </p>
                <p class="bg_content">
                  通过租住服务平台与智能硬件的相结合，提供完整的租住生命周期方案，帮助运营管理部门对公租房.人才公寓得到有效的监督与管理
                </p>
              </div>

              <div>
                <img
                  src="../assets/images/index/gzy.jpeg"
                  alt=""
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="wordFiled">
                <h3 class="fieldName">公租房</h3>
                <p class="fieldNameEnglish">Public rental</p>
              </div>
            </div>
            <div class="field" @click="goSolution('4124609738611097')">
              <div class="field_bgc">
                <h3 class="bg_fieldName">智慧(人才)公寓</h3>
                <p class="bg_fieldNameEnglish">Smart talent apartment</p>
                <p class="bg_content">
                  为公寓运营提供全场景租住解决方案，节省人力物力，全面开放赋能智慧公寓管理。
                </p>
              </div>

              <div>
                <img
                  src="../assets/images/index/gy.jpeg"
                  alt=""
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="wordFiled">
                <h3 class="fieldName">智慧(人才)公寓</h3>
                <p class="fieldNameEnglish">Smart talent apartment</p>
              </div>
            </div>
            <div class="field" @click="goSolution('8819005328326656')">
              <div class="field_bgc">
                <h3 class="bg_fieldName">智慧校园</h3>
                <p class="bg_fieldNameEnglish">Smart campus</p>
                <p class="bg_content">
                  通过校园管理平台与NB物联网锁相结合，有效的对实验室、教室、宿舍公寓行为日志等进行管理。
                </p>
              </div>

              <div>
                <img
                  src="../assets/images/index/xy.jpeg"
                  alt=""
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="wordFiled">
                <h3 class="fieldName">智慧校园</h3>
                <p class="fieldNameEnglish">Smart campus</p>
              </div>
            </div>
            <div class="field" @click="goSolution('8818659390521344')">
              <div class="field_bgc">
                <h3 class="bg_fieldName">智慧园区</h3>
                <p class="bg_fieldNameEnglish">Smart Park</p>
                <p class="bg_content">
                  为园区、办公楼提供管理方案，通过人员的集中管理，设备集中管理，提高管理效率。
                </p>
              </div>

              <div>
                <img
                  src="../assets/images/index/yq.jpeg"
                  alt=""
                  style="width: 100%; height: auto"
                />
              </div>
              <div class="wordFiled">
                <h3 class="fieldName">智慧园区</h3>
                <p class="fieldNameEnglish">Smart Park</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品中心 -->
      <div class="hot-room">
        <!-- <p class="bg_english">PRODUCT</p> -->
        <div class="project_content">
          <p class="fieldName1">智能硬件产品中心</p>
          <p class="fieldName2">多种产品支持,为您所需</p>

          <div class="hot">
            <div class="left" @click="goProductCenter">
              <div>
                <img
                  src="@/assets/images/index/NB-700.jpeg"
                  alt
                  class="leftImg"
                />
              </div>
              <div class="leftWords">
                <p style="color: black; font-size: 20px; font-weight: bold">
                  CAT-700
                </p>
                <p style="color: gray; font-size: 14px">
                  CAT.1人脸物联网锁
                </p>
              </div>
            </div>
            <div class="right">
              <div class="right_top" @click="goProductCenter">
                <div>
                  <img
                    src="@/assets/images/index/door.png"
                    alt
                    style="height: 294px; border-radius: 8px"
                  />
                </div>
                <div class="words">
                  <p style="color: black; font-size: 20px; font-weight: bold;">
                    AI人脸识别终端
                  </p>
                  <!-- <p style="color: gray; font-size: 14px">CAT.1物联网锁</p> -->
                </div>
                <div class="describe">
                  <p>AI智慧通行</p>
                  <p>更智能,更便捷&emsp;&emsp;&emsp;</p>
                </div>
              </div>
              <div class="right_top" @click="goProductCenter">
                <div>
                  <img
                    src="@/assets/images/index/L-600HS.jpg"
                    alt
                    style="height: 294px; border-radius: 8px"
                  />
                </div>
                <div class="words">
                  <p style="color: black; font-size: 20px; font-weight: bold">
                    L-600HS
                  </p>
                  <p style="color: gray; font-size: 14px">扫码物联网锁</p>
                </div>
                <div class="describe">
                  <p>一码智慧通行</p>
                  <p>构建校园精细化管理</p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>

      <!-- 核心能力 -->
      <div class="about_viedo">
        <div class="bgc_left">
          <!-- <p class="bg_english">COMPETENCE</p> -->
          <div class="project_content">
            <p class="fieldName1">技术核心能力</p>
            <p class="fieldName2">独立部署 数据私有更安全 源码合作更灵活</p>
            <!-- 
              <Row :gutter="32" style="margin-top: 20px">
                <i-col span="6" class="bg_project3">
                  <div class="bg_project3Children">
                    <div>
                      <img
                        src="@/assets/images/index/hexin1.png"
                        alt=""
                        width="56px"
                        height="56px"
                      />
                    </div>
                    <div class="applicationName">专业能力</div>
                    <div class="applicationContent">
                      专注物联网产品的研发与制造，不断的完善研发水平，专注行业领域应用，做行业内的领军品牌。
                    </div>
                  </div>
                </i-col>
                <i-col span="6" class="bg_project3">
                  <div class="bg_project3Children">
                    <div>
                      <img
                        src="@/assets/images/index/hexin2.png"
                        alt=""
                        width="56px"
                        height="56px"
                      />
                    </div>
                    <div class="applicationName">自主研发</div>
                    <div class="applicationContent">
                      公司坚持自主创新，从智能硬件与管理平台自主研发迭代升级，以用户为中心。
                    </div>
                  </div>
                </i-col>
                <i-col span="6" class="bg_project3">
                  <div class="bg_project3Children">
                    <div>
                      <img
                        src="@/assets/images/index/hexin3.png"
                        alt=""
                        width="56px"
                        height="56px"
                      />
                    </div>
                    <div class="applicationName">产品质量</div>
                    <div class="applicationContent">
                      从研发，生产，到产品检测，天诚有着完整的质量体系，为用户提供最优质的产品。
                    </div>
                  </div>
                </i-col>
                <i-col span="6" class="bg_project3">
                  <div class="bg_project3Children">
                    <div>
                      <img
                        src="@/assets/images/index/hexin4.png"
                        alt=""
                        width="56px"
                        height="56px"
                      />
                    </div>
                    <div class="applicationName">售后服务</div>
                    <div class="applicationContent">
                      全国有着365个售后服务网点，全年7*24小时的在线技术服务支撑，为客户保驾护航。
                    </div>
                  </div>
                </i-col>
              </Row> -->

            <div class="core">
              <div class="bg_project3">
                <div class="bg_project3Children">
                  <div>
                    <img
                      src="@/assets/images/index/hexin1.png"
                      alt=""
                      width="56px"
                      height="56px"
                    />
                  </div>
                  <div class="applicationName">专业能力</div>
                  <div class="applicationContent">
                    专注物联网产品的研发与制造，不断的完善研发水平，专注行业领域应用，做行业内的领军品牌。
                  </div>
                </div>
              </div>
              <div class="bg_project3">
                <div class="bg_project3Children">
                  <div>
                    <img
                      src="@/assets/images/index/hexin2.png"
                      alt=""
                      width="56px"
                      height="56px"
                    />
                  </div>
                  <div class="applicationName">自主研发</div>
                  <div class="applicationContent">
                    公司坚持自主创新，从智能硬件与管理平台自主研发迭代升级，以用户为中心。
                  </div>
                </div>
              </div>

              <div class="bg_project3">
                <div class="bg_project3Children">
                  <div>
                    <img
                      src="@/assets/images/index/hexin3.png"
                      alt=""
                      width="56px"
                      height="56px"
                    />
                  </div>
                  <div class="applicationName">产品质量</div>
                  <div class="applicationContent">
                    从研发，生产，到产品检测，天诚有着完整的质量体系，为用户提供最优质的产品。
                  </div>
                </div>
              </div>

              <div class="bg_project3">
                <div class="bg_project3Children">
                  <div>
                    <img
                      src="@/assets/images/index/hexin4.png"
                      alt=""
                      width="56px"
                      height="56px"
                    />
                  </div>
                  <div class="applicationName">售后服务</div>
                  <div class="applicationContent">
                    全国有着365个售后服务网点，全年7*24小时的在线技术服务支撑，为客户保驾护航。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- IOT-智能服务 -->
      <div class="rent">
        <div class="project_content">
          <p class="fieldName1">AIoT物联网管理平台</p>
          <p class="fieldName2">AIoT management platform</p>
          <!-- <Row
            type="flex"
            justify="space-between"
            class="code-row-bg"
            style="margin-top: 40px"
          >
            <i-col span="5" class="bg_project">
              <img src="@/assets/images/index/iot-01.png" alt width="80%" />
              <p class="rent_name">安全</p>
              <div class="rent_content">
                <p>数据隐私加密传输</p>
                <p>有效防护XSS、CSRF等WEB类攻击</p>
              </div>
            </i-col>
            <i-col span="5" class="bg_project">
              <img src="@/assets/images/index/iot-02.png" alt width="80%" />
              <p class="rent_name">高效</p>
              <div class="rent_content">
                <p>提供高速率本地/云部署</p>
                <p>大大节约时间成本</p>
              </div>
            </i-col>
            <i-col span="5" class="bg_project">
              <img src="@/assets/images/index/iot-03.png" alt width="80%" />
              <p class="rent_name">高并发</p>
              <div class="rent_content">
                <p>分布式架构有效处理数据高并发</p>
                <p>工作效率稳步提升</p>
              </div>
            </i-col>
            <i-col span="5" class="bg_project">
              <img src="@/assets/images/index/iot-04.png" alt width="80%" />
              <p class="rent_name">无缝对接</p>
              <div class="rent_content">
                <p>open docking各种平台API</p>
                <p>支持第三方设备对接平台，容错有保障</p>
              </div>
            </i-col>
          </Row> -->

          <div class="core">
            <div class="bg_project">
              <img src="@/assets/images/index/iot-01.png" alt width="80%" />
              <p class="rent_name">安全</p>
              <div class="rent_content">
                <p>数据隐私加密传输</p>
                <p>有效防护XSS、CSRF等WEB类攻击</p>
              </div>
            </div>
            <div class="bg_project">
              <img src="@/assets/images/index/iot-02.png" alt width="80%" />
              <p class="rent_name">高效</p>
              <div class="rent_content">
                <p>提供高速率本地/云部署</p>
                <p>大大节约时间成本</p>
              </div>
            </div>
            <div class="bg_project">
              <img src="@/assets/images/index/iot-03.png" alt width="80%" />
              <p class="rent_name">高并发</p>
              <div class="rent_content">
                <p>分布式架构有效处理数据高并发</p>
                <p>工作效率稳步提升</p>
              </div>
            </div>
            <div class="bg_project">
              <img src="@/assets/images/index/iot-04.png" alt width="80%" />
              <p class="rent_name">无缝对接</p>
              <div class="rent_content">
                <p>open docking各种平台API</p>
                <p>支持第三方设备对接平台，容错有保障</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import Utils from "@/utils/callUtils.js";
import {
  getProjectList,
  getHotHouseTypeList,
  getBannnerList,
  getAllProjectList,
} from "@/api/index.api";
import { getBannerList, getProductList } from "@/api/newWebsite";
export default {
  name: "Home",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚官网-智慧通行|人脸识别终端|人行通道闸机|CAT.1智能门锁|LORA/UNB联网锁|4G智能门锁|NB人脸智能门锁|WiFi无线物联网锁|智慧人才公寓管理系统|公租房管理系统",
    meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      isShowViedoWord: true,
      bannerList: [], //banner图集合
      bigHotHouseType: [],
      allProjectList: [], //所有项目
      value2: 0,
      model1: "",
      productList: [],
    };
  },
  methods: {
    goProductCenter() {
      this.$router.push({
        name: "product-center",
      });
      sessionStorage.setItem("checkedIndex", 3);
    },

    //获取banner图
    getBannerList() {
      getBannerList()
        .then((res) => {
          if (res.code == 0) {
            this.bannerList = res.data;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },

    //获取产品中心列表数据
    getProductList() {
      getProductList({
        pageNum: 1,
        pageSize: 10,
      })
        .then((res) => {
          if (res.code == 0) {
            this.productList = res.data.list.splice(1, 3);
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },
    goSolution(value) {
      this.$router.push({
        name: "solution",
        query: { id: value },
      });
      // this.reload();
      sessionStorage.setItem("checkedIndex", 4);
      this.$store.commit("changeSearchTitle", "天诚科技智慧管理系统,解决方案");
    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    // this.$store.commit("changeAppointment", true);
    // this.$store.commit("changeLocation", 230);
    this.getBannerList();
  },
  mounted() {},
};
</script>
<style scoped>
.home {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
  overflow: hidden;
}
.main-header {
  width: 100vw;
  height: calc(100vw * 5 / 13);
  text-align: center;
}
.title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 100px;
}
.title2 {
  font-size: 20px;
  padding-top: 20px;
}
.banner img {
  width: 100vw;
  height: calc(100vw * 5 / 13);
  /* height: 870px; */
}
.banner >>> .ivu-carousel-dots li {
  margin-top: -30px;
}
.banner >>> .ivu-carousel-dots li button {
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 4px;
}
.banner >>> .ivu-carousel-dots-inside {
  bottom: -10px;
}
.project_container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 62px;
}
.project {
  max-width: 1200px;
  /* height: 400px; */
  margin: auto;
}
.bg_project {
  text-align: center;
  background-color: #ffffff;
  overflow: hidden;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  width: 24%;
}
.bg_project:hover {
  box-shadow: 0px 0px 5px #888888;
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transition: all 0.6s;
}
.bg_project2 {
  overflow: hidden;
  height: 374px;
  box-shadow: 0 0 29px 0 rgb(213 217 222 / 35%);
  cursor: pointer;
}
.boderStyle {
  border-right: 1px solid #f0f0f0;
}

.bg_project2:hover {
  box-shadow: 0px 0px 5px #888888;
}
.bg_project2:hover .applicationDeatil {
  opacity: 1 !important;
  cursor: pointer;
}
.bg_project2Children {
  padding: 40px;
}

.field {
  overflow: hidden;
  min-height: 300px;
  box-shadow: 0 0 29px 0 rgb(213 217 222 / 35%);
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  width: 24%;
}
.wordFiled {
  text-align: left;
  padding: 30px 20px;
  font-family: Arial;
}
.fieldName {
  font-size: 18px;
  color: #111;
}
.fieldNameEnglish {
  font-size: 16px;
  color: #777;
  font-weight: bold;
  padding-top: 3px;
}
.bg_fieldName {
  font-size: 18px;
  margin-top: 10px;
}
.bg_fieldNameEnglish {
  font-size: 16px;
  font-weight: bold;
  padding-top: 3px;
}
.bg_content {
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  padding-right: 20px;
  line-height: 25px;
}

.field_bgc {
  background: rgba(99, 137, 242, 0.7);
  color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  display: none;
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
}
.field:hover .field_bgc {
  display: block;
}
.bg_cantainer {
  display: flex;
  justify-content: space-between;
}
.bg_project3 {
  overflow: hidden;
  /* height: 354px; */
  width: 24%;
}
.bg_project3:hover {
  box-shadow: 0px 0px 5px #888888;
  -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transition: all 0.6s;
}
.bg_project3Children {
  padding: 40px;
}

.bg_english {
  font-size: 56px;
  font-weight: bold;
  color: #cfa972;
  opacity: 0.6;
}
.project_content {
  padding-bottom: 20px;
}
.project_name {
  color: #333333;
  font-size: 18px;
  padding-left: 20px;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
}
.hot-room {
  max-width: 1200px;
  /* height: 628px; */
  margin: auto;
}
.hot {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  min-height: 613px;
}
.left {
  width: 50%;
  position: relative;
  cursor: pointer;
  /* box-shadow: rgb(54 137 245 / 30%) 0px 5px 9px 0px; */
}
.left img:hover {
  -moz-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transition: all 0.6s;
}
.img-mask {
  opacity: 1 !important;
  padding: 2px !important;
}
.right {
  flex: 1;
  margin-left: 20px;
}
.room_name {
  text-align: left;
  color: white;
  left: 20px;
  margin-left: 11px;
  margin-bottom: 20px;
}
.small_img {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 100%;
  padding-left: 10px;
  cursor: pointer;
  background: linear-gradient(transparent, rgba(1, 1, 1, 0.5));
}
.small_img img:nth-child(n + 2) {
  margin-left: 10px;
}
.smallImg_bottom {
  display: flex;
  padding-bottom: 7px;
}
.smallImg_bottom > div:nth-child(n + 2) {
  margin-left: 10px;
}
.right_top {
  height: 290px;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: rgb(54 137 245 / 30%) 0px 5px 9px 0px;
}
.right_top img {
  width: 100%;
}
.right_top:hover {
  -moz-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transition: all 0.6s;
}

.about_viedo {
  width: 100%;
  min-height: 510px;
  margin-top: 100px;
  background-color: #f5f7fa;
  padding-top: 40px;
  padding-bottom: 40px;
}
.bgc_left {
  width: 100%;
}
.bgc_left > div:nth-child(1) {
  max-width: 1200px;
  margin: auto;
}
.rent {
  max-width: 1200px;
  margin: auto;
  padding-bottom: 40px;
  padding-top: 80px;
}
.rent_name {
  margin: auto;
  width: 40%;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--themeColor);
}
.rent_content {
  margin: auto;
  text-align: center;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  padding-bottom: 31px;
  padding-top: 19px;
}
.rent_content p {
  padding-top: 6px;
}
.words {
  position: absolute;
  width: 100%;
  bottom: -3px;
  text-align: left;
  height: 96px;
  /* opacity: 0.6; */
  padding-left: 20px;
  padding-top: 30px;
  /* background: linear-gradient(transparent, rgb(205, 201, 201)); */
}
.leftWords {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: left;
  height: 96px;
  /* opacity: 0.6; */
  padding-left: 20px;
  padding-top: 30px;
  /* background: linear-gradient(transparent, rgb(205, 201, 201)); */
}
.address-container {
  display: flex;
  padding-bottom: 2px;
}
.address {
  margin-left: 10px;
  position: relative;
  top: 0px;
  color: #999999;
  padding-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.viedo-container >>> .vjs-poster {
  background-size: cover !important;
}
.viedo-container >>> .video-js:hover .vjs-big-play-button {
  background-color: var(--themeColor);
}
.viedo-container >>> .vjs-custom-skin > .video-js .vjs-big-play-button {
  width: 72px;
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 72px !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}
.viedoWord {
  z-index: 0;
  position: relative;
  font-size: 50px;
  color: #ffffff;
  font-weight: bolder;
  top: -250px;
  left: 9%;
}
.viedoWord2 {
  z-index: 0;
  position: relative;
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  top: -240px;
  left: 9%;
}
.viedo-container >>> .vjs-custom-skin > .video-js .vjs-big-play-button {
  margin-top: 0em !important;
}
.applicationName {
  font-size: 16px;
  font-weight: 600;
  color: #0b1d30;
  padding: 30px 0;
}
.applicationContent {
  color: #42506c;
  font-size: 16px;
  text-align: left;
  height: 120px;
  margin: 0;
}
.applicationDeatil {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #016fff;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.38s linear;
}
.describe {
  position: absolute;
  top: 80px;
  right: 60px;
  font-size: 16px;
  padding: 10px;
  color: #a4a6a9;
  font-weight: bold;
  text-align: left;
}
.describe p:nth-child(2) {
  margin-top: 2px;
}

.fieldName1 {
  color: #343434;
  font-size: 32px;
  font-weight: bold;
}
.fieldName2 {
  color: #98a0a6;
  font-size: 18px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.leftImg {
  width: 100%;
  height: 603px;
  object-fit: cover;
  border-radius: 8px;
}
.core {
  display: flex;
  justify-content: space-between;
}
.bg_project > img {
  width: 80%;
}

@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .bg_cantainer {
    display: block;
  }
  .field {
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
  }
  .fieldName1 {
    font-size: 18px;
  }
  .fieldName2 {
    font-size: 14px;
  }
  .hot {
    display: block;
  }
  .left {
    width: 100%;
    margin: auto;
    margin-bottom: 15px;
  }
  .leftImg {
    width: 100%;
    height: auto;
  }
  .right {
    margin-left: 0;
  }
  .describe {
    right: 30px;
  }
  .words {
    bottom: -20px;
  }
  .bg_project3 {
    width: 80%;
    margin: auto;
  }
  .core {
    display: block;
  }
  .rent {
    padding-top: 0;
  }
  .about_viedo {
    background-color: white;
    margin-top: 10px;
  }
  .bg_project {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  .bg_project > img {
    width: 50%;
  }
}
</style>

import { post,postJson } from '../libs/api.request'

//获取我的收藏房源
export const getMyCollection = (params) => post('/api/utCustomerFavorite/getAllDataList', params);

//获取我的预约
export const getMyAppointment = (params) => post('/api/utCustomerIntentLooking/getAllDataList', params);

//投诉管理
export const goComplaint = (params) => postJson('/api/utCustomerComplain/saveData', params);

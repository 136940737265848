<template>
  <div class="header-wrapper">
    <div class="header-nav">
      <div class="header-img">
        <img
          :src="require('@/assets/images/index/newLogo3.png')"
          alt
          style="cursor: pointer"
          @click="goHome"
          width="200px;"
        />
      </div>
      <div class="nav">
        <ul style="color: white">
          <!-- <li
            @click="handleClick(1)"
            :class="checkedIndex == 1 ? 'active' : ''"
          >
            首页
          </li>
          <li
            @click="handleClick(2)"
            :class="checkedIndex == 2 ? 'active' : ''"
          >
            新闻中心
          </li>
          <li
            @click="handleClick(3)"
            :class="checkedIndex == 3 ? 'active' : ''"
          >
            产品中心
          </li>
          <li
            :class="checkedIndex == 4 ? 'active' : ''"
            @mouseenter="mouseCode"
          >
            解决方案
          </li>
          <li
            :class="checkedIndex == 5 ? 'active' : ''"
            @click="handleClick(5)"
          >
            合作伙伴
          </li>
          <li
            @click="handleClick(6)"
            :class="checkedIndex == 6 ? 'active' : ''"
          >
            关于我们
          </li>
          <li
            @click="handleClick(7)"
            :class="checkedIndex == 7 ? 'active' : ''"
          >
            技术支持
          </li> -->
          <li
            @click="handleClick(item.orderFiled, item.gatewayName)"
            :class="[
              checkedIndex == item.orderFiled ? 'active' : '',
              isShowHoversInex == item.orderFiled ? 'hovers' : '',
            ]"
            v-for="item in navList"
            :key="item.id"
            @mouseenter="mouseCode(item.orderFiled)"
            @mouseleave="mouseCodeLeave2(item.orderFiled)"
          >
            {{ item.name }}
          </li>
        </ul>

        <!-- 产品中心的悬浮页面 -->
        <div class="product" 
          :style="{right:productRight+'px'}" v-show="isShowProduct" @mouseenter="onMouseCode(3)" @mouseleave="mouseProductLeave">
          <div class="boxWarp" :style="{height:productHeight+'px'}">
            <p
              @click="goProduct(item.id, item.title)"
              v-for="item in productList"
              :key="item.id"
              :class="{ selectMenu: isActive(item.id) }"
            >
              {{ item.name }}
            </p>
          </div>
        </div>


        <!-- 解决方案的悬浮页面 -->
        <div class="AppCode" v-show="isCodeShow" @mouseenter="onMouseCode(4)" @mouseleave="mouseCodeLeave">
          <div class="boxWarp">
            <p
              @click="goSolution(item.id, item.searchTitle)"
              v-for="item in solutionList"
              :key="item.id"
              :class="{ selectMenu: isActive(item.id) }"
            >
              {{ item.title }}
            </p>
          </div>
        </div>

        <!-- 合作案例的悬浮页面 -->
        <div class="AppCodeCooperate" v-show="isCooperateShow" @mouseenter="onMouseCode(5)" @mouseleave="mouseCodeLeaveCooperate">
          <div class="boxWarp">
            <p
              @click="goCooperate(item.id)"
              v-for="item in cooperateList"
              :key="item.id"
              :class="{ selectMenu: isActive(item.id) }"
            >
              {{ item.title }}
            </p>
          </div>
        </div>
      </div>

      <div class="system">
        <div style="cursor: pointer" @click="goIOT">
          <img
            src="../assets/images/index/iotLogo.png"
            alt=""
            width="25px"
            height="25px"
          />
          <p style="color: white; font-weight: bold">AIOT</p>
        </div>

        <div style="margin-left: 30px; cursor: pointer" @click="goPark">
          <img
            src="../assets/images/index/parkLogo.png"
            alt=""
            width="25px"
            height="25px"
          />
          <p style="color: white; font-weight: bold">园区</p>
        </div>

        <div style="margin-left: 30px; cursor: pointer" @click="goSchool">
          <img
            src="../assets/images/index/schoolLogo.png"
            alt=""
            width="25px"
            height="25px"
          />
          <p style="color: white; font-weight: bold">校园</p>
        </div>

        <div style="margin-left: 30px; cursor: pointer" @click="goHotel">
          <img
            src="../assets/images/index/hotelLogo.png"
            alt=""
            width="25px"
            height="25px"
          />
          <p style="color: white; font-weight: bold">公寓</p>
        </div>

        <div style="margin-left: 30px; cursor: pointer; width: 36px" @click="goInternetRoom">
          <img
            src="../assets/images/index/internetLogo.png"
            alt=""
            width="25px"
            height="25px"
          />
          <p style="color: white; font-weight: bold">网约房</p>
        </div>
      </div>
    </div>

    <!-- 移动端小屏导航栏 -->
    <div class="mobile-cantainer">
      <div class="mobile-nav">
        <div class="header-img2">
          <img
            :src="require('@/assets/images/index/newLogo4.png')"
            alt
            style="cursor: pointer"
            @click="goHome"
            width="150px;height:52px"
          />
        </div>
        <div>
          <Dropdown trigger="click" @on-click="handler">
            <a href="javascript:void(0)">
              <Icon
                type="md-reorder"
                size="30"
                color="white"
                @click="handlerIcon"
              />
            </a>
            <DropdownMenu slot="list">
              <DropdownItem
                :name="item.gatewayName"
                v-for="(item, index) in navList"
                :key="index"
                >{{ item.name }}</DropdownItem
              >
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      <!-- 移动端-合作案例的悬浮页面 -->
      <div class="newProduct" v-if="isShowNewProduct">
        <p
          @click="goProduct(item.id, item.title)"
          v-for="item in productList"
          :key="item.id"
        >
          {{ item.name }}
        </p>
      </div>

      <!-- 移动端-解决方案的悬浮页面 -->
      <div class="newtest" v-if="isShowMenu">
        <p
          @click="goSolution(item.id, item.searchTitle)"
          v-for="item in solutionList"
          :key="item.id"
        >
          {{ item.title }}
        </p>
      </div>

      <!-- 移动端-合作案例的悬浮页面 -->
      <div class="newtestCooperation" v-if="isShowCooperation">
        <p
          @click="goCooperate(item.id)"
          v-for="item in cooperateList"
          :key="item.id"
        >
          {{ item.title }}
        </p>
      </div>

      <!-- <Icon
        type="md-reorder"
        size="30"
        color="white"
        style="cursor: pointer"
        @click="clickShow"
      /> -->
      <!-- <div class="navMobilelist" v-if="isShowMenu">
        <ul style="color: black">
          <li
            @click="handleClick(item.orderFiled, item.gatewayName)"
            v-for="item in navList"
            :key="item.id"
          >
            {{ item.name }}
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getProductIdList,getSolutionList, getNavigationList } from "@/api/newWebsite";
import { json } from "body-parser";
export default {
  name: "header-nav",
  data() {
    return {
      isShowHoversInex:-100,  //多级目录的悬浮效果展示下标
      checkedIndex: 1,
      isAboutShow: false,
      isShowProduct:false,
      isCodeShow: false,
      isCooperateShow: false,
      env_type: "pro",
      projectType: "",
      productList:[], //产品中心二级路由
      solutionList: [],
      navList: [],
      isShowNewProduct:false,
      isShowMenu: false,
      isShowCooperation: false,
      cooperateList: [
        { id: 1, title: "合作客户" },
        { id: 2, title: "校园案例" },
        { id: 3, title: "公租房人才公寓" },
      ],
      productRight:710,
      productHeight:225,
    };
  },
  inject: ["reload"],
  methods: {
    isActive(id) {
      return this.$store.state.selectids === id;
    },

    clickShow() {
      this.isShowMenu = !this.isShowMenu;
    },

    goIOT() {
      window.open("https://5gnbiot.com.cn/iot");
    },
    goSchool() {
      // window.open("https://5gnbiot.com.cn/school");
      window.open("https://tcsmartschool.com/#/login");
    },
    goPark(){
      window.open("https://tcsmartpark.com/#/login");
    },
    goHotel() {
      window.open("https://5gnbiot.com.cn/hotel/saas");
    },
    goInternetRoom() {
      window.open("https://5gnbiot.com.cn/netroom");
    },
    goHome() {
      this.$router.push({ name: "Home" });
      sessionStorage.setItem("checkedIndex", 1);
    },
    mouseCode(value) {
      // console.log('导航栏进入的value值',value)
      this.isShowHoversInex = value;
      this.isShowProduct = false;
      this.isCodeShow = false;
      this.isCooperateShow = false;
      if(value == 3) {
        //产品中心
        this.isShowProduct = true;
        // this.checkedIndex = 3;
      } else if (value == 4) {
        //解决方案
        this.isCodeShow = true;
        // this.checkedIndex = 4;
      } else if (value == 5) {
        //合作伙伴
        this.isCooperateShow = true;
        // this.checkedIndex = 5;
      }
    },
    mouseCodeLeave2(value) {
      // console.log('导航栏离开的value值',value)
      this.isShowHoversInex = -100;
      this.isShowProduct = false;
      this.isCodeShow = false;
      this.isCooperateShow = false;
    },

    onMouseCode(value) {
      this.isShowHoversInex = value;
      this.isShowProduct = false;
      this.isCodeShow = false;
      this.isCooperateShow = false;
      if(value == 3){
        this.isShowProduct = true;
      }if(value == 4){
        this.isCodeShow = true;
      }else if(value == 5){
        this.isCooperateShow = true;
      }
    },

    mouseProductLeave(){
      this.isShowHoversInex = -100;
      this.isShowProduct = false;
      // this.checkedIndex = sessionStorage.getItem("checkedIndex");
    },

    mouseCodeLeave() {
      this.isShowHoversInex = -100;
      this.isCodeShow = false;
      // this.checkedIndex = sessionStorage.getItem("checkedIndex");
    },

    mouseCodeLeaveCooperate() {
      this.isShowHoversInex = -100;
      this.isCooperateShow = false;
      // this.checkedIndex = sessionStorage.getItem("checkedIndex");
    },
    // mouseAbout() {
    //   this.checkedIndex = 4;
    //   this.isAboutShow = true;
    // },
    // mouseAboutLeave() {
    //   this.isAboutShow = false;
    // },
    handleClick(index, routerName) {
      if(index != 3 && index != 4 && index != 5){
        this.checkedIndex = index;
        sessionStorage.setItem("checkedIndex", index);
        this.$store.commit("changeSelectids", '');
      }
      switch (index) {
        case 1:
          // this.$router.push({ name: "Home" });
          this.$router.push({ name: routerName });
          break;
        case 2:
          // this.$router.push({ name: "press-center" });
          this.$router.push({ name: routerName });
          break;
        case 3:
          // this.$router.push({ name: "product-center" });
          // this.$router.push({ name: routerName });
          break;
        case 4:
          // this.$router.push({ name: "" });
          // console.log("路由名", routerName);
          // this.$router.push({ name: routerName });
          break;
        case 5:
          // this.$router.push({ name: "cooperation" });
          // this.$router.push({ name: routerName });
          break;
        case 6:
          // this.$router.push({ name: "about-us" });
          this.$router.push({ name: routerName });
          break;
        case 7:
          // this.$router.push({ name: "technical-support" });
          this.$router.push({ name: routerName });
          break;
        case 8:
          this.$router.push({ name: routerName });
          break;
        // case 9:
        //   this.$store.commit("changeCurrentNav", 1);
        //   break;
      }
    },

    handler(value) {
      // console.log("小屏幕点击", value);
      this.isShowNewProduct = false;
      this.isShowMenu = false;
      this.isShowCooperation = false;
      if (value == "null") {
        // this.$router.push({
        //   name: "solution",
        //   query: { id: this.$store.state.solutionId },
        // });
        this.isShowMenu = !this.isShowMenu;
      } else if (value == "nullCooperation") {
        this.isShowCooperation = !this.isShowCooperation;
      } else if(value == "product-center") {
        this.isShowNewProduct = !this.isShowNewProduct;
      } else {
        this.$router.push({ name: value });
      }
    },

    handlerIcon() {
      this.isShowNewProduct = false;
      this.isShowMenu = false;
      this.isShowCooperation = false;
    },

    // 去往产品中心
    goProduct(value, title){
      this.$store.commit("changeSelectids", value);
      this.$router.push({
        name: "product-center",
        query: { id: value },
      });
      // this.reload();
      this.isShowProduct = false;
      this.checkedIndex = 3;
      sessionStorage.setItem("checkedIndex", 3);
      this.$store.commit("changeSearchTitle", title);
    },

    goSolution(value, title) {
      this.$store.commit("changeSelectids", value);
      this.$router.push({
        name: "solution",
        query: { id: value },
      });
      this.reload();
      sessionStorage.setItem("checkedIndex", 4);
      this.$store.commit("changeSearchTitle", title);
    },

    //去往合作案例  （1 合作客户，2 校园案例，3 公租房人才公寓案例）
    goCooperate(value) {
      // console.log("合作案例", value);
      this.$store.commit("changeSelectids", value);
      if (value == 1) {
        this.$router.push({
          name: "cooperation",
        });
      } else if (value == 2) {
        this.$router.push({
          name: "school-cooperation",
        });
      } else if (value == 3) {
        this.$router.push({
          name: "public-cooperation",
        });
      }
      sessionStorage.setItem("checkedIndex", 5);
    },
    
    // 获取产品类别
    getProductIdList() {
      getProductIdList()
        .then((res) => {
          if (res.code == 0) {
            this.productList = res.data;
            this.$store.commit("changeProductId", this.productList[0].id); //解决方案第一个id赋值
            let maxLength = res.data.length;
            if(maxLength > 0){
              if(maxLength <= 5){
                this.productRight = 710;
                this.productHeight = maxLength * 45;
              }else{
                let num = Math.ceil(maxLength / 5) - 1;
                this.productRight = 710 - 160 * num;
                this.productColumn = 5;
              }
            }
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取解决方案数据失败,请稍后再试");
        });
    },

    //获取解决方案列表
    getSolutionList() {
      getSolutionList()
        .then((res) => {
          if (res.code == 0) {
            this.solutionList = res.data;
            this.$store.commit("changeSolutionId", this.solutionList[0].id); //解决方案第一个id赋值
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取解决方案数据失败,请稍后再试");
        });
    },

    //获取导航栏数据
    getNavigationList() {
      getNavigationList()
        .then((res) => {
          if (res.code == 0) {
            // console.log("导航栏数据",res)
            this.navList = res.data;
            this.$store.commit("changeNavList", this.navList);
            localStorage.setItem("navList", JSON.stringify(this.navList));
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取导航栏数据失败,请稍后再试");
        });
    },
  },
  created() {
    this.env_type = process.env.VUE_APP_ENV_TYPE;
    this.projectType = process.env.NODE_ENV;
    this.checkedIndex = sessionStorage.getItem("checkedIndex")
      ? sessionStorage.getItem("checkedIndex")
      : "1";

    this.getNavigationList();
    this.getProductIdList();
    this.getSolutionList();

    
    if(this.checkedIndex == 1){
      this.$store.commit("changeSelectids", '')
    }
  },
};
</script>

<style scoped>
.test {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 0.8rem;
  color: #ea5413;
  font-weight: 500;
}
.header-wrapper {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 999;
  background-color: #234166;
  box-shadow: 0 0 3px 0;
}
.header-nav {
  max-width: 1300px;
  height: 100%;
  background-color: #234166;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.header-img {
  width: 160px;
  padding-top: 15px;
}
.header-img2 {
  width: 160px;
}
.address {
  width: 10%;
  margin-left: -60px;
}
.nav {
  width: 80%;
  padding-top: 12px;
}
.system {
  flex: 1;
  margin-top: 10px;
  display: flex;
}

.nav >>> .ivu-menu .ivu-menu-light .ivu-menu-horizontal {
  border-bottom: none !important;
}
.nav > ul {
  list-style: none;
}
.nav > ul > li {
  display: inline-block;
  cursor: pointer;
  margin-left: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 15px;
  text-align: center;
}
.nav > ul > li:hover {
  color:#cfa972;
  font-weight: bold;
}
.nav > ul > li.hovers {
  color: #cfa972;
  font-weight: bold;
}
.active {
  color: #cfa972;
  font-weight: bold;
  border-bottom: 3px solid#CFA972;
}
.selectMenu{
  font-weight: bold;
  color: #CFA972;
}
.product {
  /* width: 160px; */
  font-weight: 500;
  position: absolute;
  right: 710px;
  top: 72px;
  color: white;
  font-size: 15px;
  padding-top:10px;
}
.product .boxWarp{
  background-color: #234166;
  box-shadow: 0 0 5px 0;
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-auto-flow: column;  /* 列优先布局 */
  grid-template-rows: repeat(5, minmax(45px, auto)); /* 固定5行 */
  grid-auto-columns: 1fr;  /* 自动列宽 */
}
.product p {
  height: 45px;
  line-height: 45px;
  width: 160px;
}
.product p:hover {
  background-color: #cfa972;
  color: #FFFFFF;
  cursor: pointer;
}
.product img {
  width: 78px;
  height: 78px;
}

.AppCode {
  width: 260px;
  font-weight: 500;
  position: absolute;
  right: 520px;
  top: 72px;
  color: white;
  font-size: 15px;
  padding-top:10px;
}
.AppCode .boxWarp{
  background-color: #234166;
  box-shadow: 0 0 5px 0;
}
.AppCode p {
  height: 45px;
  line-height: 45px;
}
.AppCode p:hover {
  background-color: #cfa972;
  color: #FFFFFF;
  cursor: pointer;
}
.AppCode img {
  width: 78px;
  height: 78px;
}

.AppCodeCooperate {
  width: 160px;
  font-weight: 500;
  position: absolute;
  right: 530px;
  top: 72px;
  color: white;
  font-size: 15px;
  padding-top:10px;
}
.AppCodeCooperate .boxWarp{
  background-color: #234166;
  box-shadow: 0 0 5px 0;
}
.AppCodeCooperate p {
  height: 45px;
  line-height: 45px;
}
.AppCodeCooperate p:hover {
  background-color: #cfa972;
  color: #FFFFFF;
  cursor: pointer;
}
.AppCodeCooperate img {
  width: 78px;
  height: 78px;
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* line-height: 70px; */
  padding-top: 12px;
}
.navMobilelist {
  padding-left: 0px;
  margin-top: 0px;
  background: #e8e8e8;
  width: 100%;
  min-height: 150px;
  float: left;
  /* display: none; */
  box-shadow: 0 0 29px 0 rgb(213 217 222 / 35%);
  min-width: 100%;
}

.newProduct{
  min-width: 110px;
  min-height: 100px;
  position: absolute;
  right: 5px;
  margin-top: 5px;
  border-radius: 4px;
  background-color: white;
  color: #515a6e;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 1px rgb(207, 203, 203);
}
.newProduct p{
  padding: 10px;
}

.newtest {
  min-width: 150px;
  min-height: 100px;
  position: absolute;
  right: 5px;
  margin-top: 5px;
  border-radius: 4px;
  background-color: white;
  color: #515a6e;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 1px rgb(207, 203, 203);
}
.newtest p {
  padding: 10px;
}

.newtestCooperation {
  min-width: 110px;
  min-height: 100px;
  position: absolute;
  right: 5px;
  margin-top: 5px;
  border-radius: 4px;
  background-color: white;
  color: #515a6e;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  box-shadow: 0px 0px 0px 1px rgb(207, 203, 203);
}
.newtestCooperation p {
  padding: 10px;
}
@media screen and (max-width: 768px) {
  .header-wrapper {
    height: 50px;
  }
  .header-nav {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .mobile-cantainer {
    display: none;
  }
}
</style>
import 'babel-polyfill';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import iView from 'iview';
import config from '@/config'
import BaiduMap from 'vue-baidu-map';
import 'iview/dist/styles/iview.css';
import axios from 'axios'; /* 引入axios进行地址访问*/
import qs from 'qs';
import $ from "jquery";
import "./index.less";
// 全局组件定位栏
import location from "./components/location-column";
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import Viewer from 'v-viewer' // 图片预览
import 'viewerjs/dist/viewer.css'
import 'lib-flexible'
// import versionTood from '@/libs/versionUpdate'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.use(iView)
Vue.use(BaiduMap, {
    ak: 'ktCuYh5pEeT5WKVWCssOk36I80M2MXq5'
})
Vue.component('location-column', location)
Vue.prototype.$http = axios;
Vue.prototype.qs = qs
Vue.use(qs)
Vue.use(VideoPlayer)
Vue.use(Viewer)
Vue.prototype.$config = config
Vue.config.productionTip = false;


window.globalEvents = new Vue() // 注册全局事件
//禁止浏览器后退
window.addEventListener('popstate', function () {
    history.pushState(null, null, document.URL)
})

// var _hmt = _hmt || [];
// window._hmt = _hmt; // 将_hmt挂载到window下
// (function () {
//     var hm = document.createElement("script");
//     hm.src = "https://hm.baidu.com/hm.js?30767bf7f8b8ee679ff88eb4c4c960ee";
//     var s = document.getElementsByTagName("script")[0];
//     s.parentNode.insertBefore(hm, s);
// })();


// router.beforeEach((to, from, next) => {
//     // if (_hmt) {
//     //     if (to.path) {
//     //         _hmt.push(['_trackPageview', '/#' + to.fullPath]);
//     //     }
//     // }

//     //百度统计代码
//     if (window._hmt && to.path) {
//         window._hmt.push(['_trackPageview', '#' + to.path]);
//     }

//     // chrome
//     document.body.scrollTop = 0
//     // firefox
//     document.documentElement.scrollTop = 0
//     // safari
//     // window.pageYOffset = 0
//     //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
//     // versionTood.isNewVersion();
//     next()
// })



new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        window.document.dispatchEvent(new Event('render-event'))
    }
}).$mount("#app");
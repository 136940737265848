import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // currentNav: 1,
        // livenewsType: '新寓说News',
        // newsCurrentNav: 1,
        // isShowUserCenter: false,
        // isShowLogin: true,
        // isShowRegister: true,
        // isShowAppointment: true,
        locationColumn: 230,
        selectids:"",
        productId:"",
        //解决方案第一个id 为了底部导航栏的跳转
        solutionId: "",
        navList: [],
        searchTitle: ""
    },
    mutations: {
        // changeCurrentNav(state, data) {
        //     state.currentNav = data
        // },
        // changeLivenewsType(state, data) {
        //     state.livenewsType = data
        // },
        // changeNewsCurrentNav(state, data) {
        //     state.newsCurrentNav = data
        // },
        // changeUserCenter(state, data) {
        //     state.isShowUserCenter = data
        // },
        // changeLogin(state, data) {
        //     state.isShowLogin = data
        // },
        // changeRegister(state, data) {
        //     state.isShowRegister = data
        // },
        // changeAppointment(state, data) {
        //     state.isShowAppointment = data
        // },
        // changeLocation(state, data) {
        //     state.locationColumn = data
        // },
        changeSelectids(state, data) {
            state.selectids = data
        },
        changeProductId(state, data) {
            state.productId = data
        },
        changeSolutionId(state, data) {
            state.solutionId = data
        },
        changeNavList(state, data) {
            state.navList = data
        },
        changeSearchTitle(state, data) {
            state.searchTitle = data
        }
    },
    actions: {},
    modules: {}
});